<template>
    <div class="container-fluid bg-light py-3">
        <main class="container-xl">
            <h1>{{ titulo }}</h1>
            <p class="fs-3 mt-4"> {{descripcion}}</p>
            <div class="row">
              <span>
                <img :src="pathLogo" alt="/" height="80">
              </span>
              <span>
                <img :src="pathImg" alt="/" class="w-75">
              </span>
              <span>
                <a href="#" @click.prevent="$emit('verFormDescarga',5)" class="btn btn-success fw-bold me-2">Agenda cita</a>
              </span>
            </div>
            
        </main>
        <section class="container-xl pt-5">
            <h4>Servicios de telefonía IP y colaboración</h4>
            <span>
                <router-link class="btn" to="/servicios/gotoconnect">
                    <img src="../assets/LMI_GoToConnect_Green_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/gotomeeting">
                    <img src="../assets/LMI_GoToMeeting_Orange_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/gotowebinar">
                    <img src="../assets/LMI_GoToWebinar_Blue_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/logmeinrescue">
                    <img src="../assets/LMI_Rescue_Blue_HEX.png" alt="/" height="80">
                </router-link>
                <router-link class="btn" to="/servicios/logmeinpro">
                    <img src="../assets/LMI_Pro.png" alt="/" height="80">
                </router-link>
            </span>
        </section>
    </div>
</template>
<script>
export default {
    name: 'Servicio',
    props: {
        titulo: {type: String, required: true },
        descripcion: {type: String, required: true},
        pathLogo: {type: String, required: true },
        pathImg: {type: String, required: true },
        fondo: {type: Boolean, default: false}
    }
}
</script>